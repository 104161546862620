import React from 'react'
import LandingPage from '../../../templates/landing-page'

import content from '../../../data/pages/lp/article/7-reasons-why/content.json'

const Listicle7ReasonsWhyLP = () => (
  <LandingPage content={content} type='listicle' />
)

export default Listicle7ReasonsWhyLP
